import { IMenuItemType } from "router/routerType";
import { UserSwitchOutlined } from "@ant-design/icons";
import AppUsersScreen from "../screens/AppUsersScreen";

/** Screen: Dashboard Page */
export const APP_USERS_SCREEN: IMenuItemType = {
  id: "appUsers",
  path: "/app-users",
  component: AppUsersScreen,
  title: "App Users",
  icon: UserSwitchOutlined,
  permissions: [],
};
