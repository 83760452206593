import React, { memo, useState, useEffect, useContext, Suspense } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { PRIVATE_ROUTES, PUBLIC_ROUTES, SIDER_MENU } from "./Router.config";
import values from "lodash/values";
import Layout from "ui/Layout/Layout";
import ErrorScreen from "./ErrorScreen";
import { clearTokens, isAuthenticated } from "features/identity/authHelper";
import { UserContext } from "features/identity/userContext";
import { getUser } from "features/identity/authApi";
import Loading from "ui/Loading/Loading";
import Permission from "features/identity/Permission/Permission";

const filteredRoutes = values(PRIVATE_ROUTES).filter((item) => item.component);

const Router = () => {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    /** Check on every render if set-new-password or
     * forgot-password in the path then clear tokens
     */
    if (
      location.pathname.includes(
        PUBLIC_ROUTES.SET_NEW_PASSWORD_SCREEN.path.split("/", 2)[1]
      ) ||
      location.pathname === PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path
    ) {
      clearTokens();
      setUser({});
    }

    //TODO: FIX GET/ REFRESH USER FROM COGNITO
    if (isAuthenticated() && !user.authenticated) {
      setLoading(true);
      getUser()
        .then((res) => {
          setUser({
            ...res.data,
            authenticated: true,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [location, setUser, user.authenticated]);

  if (loading) {
    return <Loading isFullscreen />;
  }

  return (
    <Suspense fallback={<Loading isFullscreen />}>
      {user?.authenticated && isAuthenticated() ? (
        <Switch>
          {filteredRoutes.map((item) => (
            <Route
              key={item.id}
              exact
              path={item.path}
              render={(props) => (
                <Permission
                  requiredPermissions={item.permissions}
                  userPermissions={user.permissions}
                >
                  <Layout {...props} item={item} siderMenu={SIDER_MENU}>
                    <item.component {...props} />
                  </Layout>
                </Permission>
              )}
            />
          ))}

          <Redirect exact from="/" to={PRIVATE_ROUTES.DASHBOARD_SCREEN.path} />

          <Route
            path="*"
            render={(props) => (
              <Layout {...props}>
                <ErrorScreen {...props} />
              </Layout>
            )}
          />
        </Switch>
      ) : (
        <Switch>
          {values(PUBLIC_ROUTES).map((item) => (
            <Route
              key={item.id}
              exact
              path={item.path}
              component={item.component}
            />
          ))}
          <Redirect to={PUBLIC_ROUTES.LOGIN_SCREEN.path} />
        </Switch>
      )}
    </Suspense>
  );
};

export default memo(Router);
