import React, { memo } from "react";
import { Table as AntDTable } from "antd";
import { TableProps } from "antd/lib/table";
import styles from "./Table.module.scss";

interface IProps extends TableProps<object> {
  columns?: any[];
}

const Table = ({ columns, pagination, ...rest }: IProps) => {
  return (
    <AntDTable
      columns={columns}
      className={styles.table}
      pagination={!!pagination && { ...pagination, position: ["bottomCenter"] }}
      rowKey={"id"}
      scroll={{ x: true }}
      {...rest}
    />
  );
};

export default memo(Table);
