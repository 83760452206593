import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";

export const getInfluencers = (params?: object) => {
  return api
    .get(Endpoints.INFLUENCERS, {
      params: { ...params },
    })
    .then((response) => response.data);
};

export const listInfluencer = (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/list";
  return api.post(endpoint).then((response) => response.data);
};

export const getInfluencerDropStatus = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/status";
  return api.get(endpoint).then((response) => response.data);
};

export const setStartDropProcessInfluencer = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/drop";
  const response = await api.post(endpoint);
  return response.data;
};

export const getInfluencerDetails = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/details";
  return api.get(endpoint).then((response) => response.data);
};
