import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";

export const getAppUsers = (params?: object) => {
  return api
    .get(Endpoints.APP_USERS, {
      params: { ...params },
    })
    .then((response) => response.data);
};

export const getCardsPerAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/cards`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getOrdersPerAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/orders`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getTransactionsPerAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/transactions`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getWithdrawRequestPerAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/withdraw-requests`;
  const response = await api.get(endpoint);
  console.log("withdraw-request", response.data);
  return response.data;
};
export const getPaymentsMethodsAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/payment-methods`;
  const response = await api.get(endpoint);
  console.log("Payments", response.data);
  return response.data;
};
