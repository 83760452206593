import axios, { AxiosRequestConfig } from "axios";
import { defaultSettings as ENV } from "./config";
import qs from "query-string";
import { ResponseStatus } from "./apiConst";
import {
  clearTokens,
  setAccessToken,
  setIdToken,
  setRefreshToken,
  getIdToken,
} from "features/identity/authHelper";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import UserPool from "features/identity/UserPool";

/** Excluded endpoints for authorization */
// const anonymousEndpoints = [Endpoints.LOGIN];

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  // const isAnonymous = anonymousEndpoints.includes(request.url || "");

  const idToken = getIdToken();

  if (!idToken) {
    clearTokens();
    return Promise.reject(ResponseStatus.UNAUTHORIZED);
  }

  request.headers["Authorization"] = `Bearer ${idToken}`;
  return request;
};

/**
 * Refresh expired tokens
 *
 * @param failedRequest
 * @returns
 */
function refreshExpiredTokens(failedRequest): Promise<any> {
  return new Promise((resolve, reject) => {
    let cognitoUser = UserPool.getCurrentUser();
    cognitoUser?.getSession((err, res) => {
      const refresh_token = res.getRefreshToken();
      cognitoUser?.refreshSession(refresh_token, (err, session) => {
        if (err) {
          reject(err);
        } else {
          setAccessToken(session.getAccessToken().getJwtToken());
          setIdToken(session.getIdToken().getJwtToken());
          setRefreshToken(session.getRefreshToken().getJwtToken());
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + session.getIdToken().getJwtToken();
          resolve(failedRequest);
        }
      });
    });
  });
}

/**
 * Setup an API instance
 */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: {
    "Content-Type": "application/json",
    "ii-api-version": "3",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "index" });
  },
});

/** Add interceptor */
api.interceptors.request.use(authInterceptor);
createAuthRefreshInterceptor(api, refreshExpiredTokens);
