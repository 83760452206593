import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import Drawer from "ui/Drawer/Drawer";
import { AppUserType } from "features/appUsers/types/AppUserType";
import Loading from "ui/Loading/Loading";
import { getWithdrawRequestPerAppUser } from "features/appUsers/api/appUsersApi";
import { errorResponseMessages } from "api/apiErrorHelper";
import { useLocation } from "react-router-dom";
import { WithdrawRequest } from "features/appUsers/types/WithdrawRequest";
import styles from "./WithdrawRequestDrawer.module.scss";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const WithdrawRequestDrawer = ({
  visible,
  refreshApi,
  onClose,
  appUser,
}: IProps) => {
  const [appUserWithdrawRequestLoading, setappUserWithdrawRequestLoading] =
    useState<boolean>(false);
  const [appUserWithdrawRequest, setappUserWithdrawRequest] =
    useState<WithdrawRequest[]>();
  const location = useLocation();

  const onCloseDrawer = () => {
    onClose && onClose();
  };

  const geAppUserWithdrawAPI = useCallback(() => {
    if (appUser) {
      setappUserWithdrawRequestLoading(true);
      getWithdrawRequestPerAppUser(appUser?.id)
        .then((res) => {
          setappUserWithdrawRequest(res);
        })
        .catch((e) => {
          errorResponseMessages(e);
        })
        .finally(() => {
          setappUserWithdrawRequestLoading(false);
        });
    }
  }, [appUser, visible]);

  useEffect(() => {
    geAppUserWithdrawAPI();
  }, [geAppUserWithdrawAPI]);

  const columns: ColumnsType = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
      width: "2%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span>{text}</span>,
      width: "8%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text}</span>,
      width: "8%",
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{text}</span>,
      width: "8%",
    },
    {
      title: "App User Payment Method",
      children: [
        {
          title: "Name",
          dataIndex: "appUserPaymentMethod",
          key: "appUserPaymentMethod",
          render: (appUserPaymentMethod) => (
            <>
              <Popover
                style={{ width: 500 }}
                content={appUserPaymentMethod.name}
                title="Payment Method Name"
                trigger="hover"
              >
                {appUserPaymentMethod.name}
              </Popover>
            </>
          ),
          width: "10%",
        },
        {
          title: "Is Default",
          dataIndex: "appUserPaymentMethod",
          key: "appUserPaymentMethod",
          render: (appUserPaymentMethod) => (
            <span>{appUserPaymentMethod.isDefault ? "true" : "false"}</span>
          ),
          width: "3%",
        },
        {
          title: "PlaidAccessToken",
          dataIndex: "appUserPaymentMethod",
          key: "appUserPaymentMethod",
          render: (appUserPaymentMethod) => (
            <>
              <Popover
                style={{ width: 500 }}
                content={appUserPaymentMethod.plaidAccessToken}
                title="Plaid Access Token"
                trigger="hover"
              >
                {appUserPaymentMethod.plaidAccessToken}
              </Popover>
            </>
          ),
          width: "10%",
        },
        {
          title: "PlaidAccountId",
          dataIndex: "appUserPaymentMethod",
          key: "appUserPaymentMethod",
          render: (appUserPaymentMethod) => (
            <>
              <Popover
                style={{ width: 500 }}
                content={appUserPaymentMethod.plaidAccountId}
                title="plaidAccountId"
                trigger="hover"
              >
                {appUserPaymentMethod.plaidAccountId}
              </Popover>
            </>
          ),
          width: "5%",
        },
        {
          title: "DwollaFundingSourceUrl",
          dataIndex: "appUserPaymentMethod",
          key: "appUserPaymentMethod",
          render: (appUserPaymentMethod) => (
            <>
              <Popover
                style={{ width: 500 }}
                content={appUserPaymentMethod.dwollaFundingSourceUrl}
                title="Dwolla Funding Source Url"
                trigger="hover"
              >
                {appUserPaymentMethod.dwollaFundingSourceUrl}
              </Popover>
            </>
          ),
          width: "10%",
        },
      ],
    },
  ];

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={"90%"}
    >
      <div className={styles.dropStatusHeadder}>
        {!appUserWithdrawRequestLoading && (
          <>
            <Card>
              <Table
                loading={appUserWithdrawRequestLoading}
                columns={columns}
                dataSource={appUserWithdrawRequest}
              />
            </Card>
          </>
        )}
        {appUserWithdrawRequestLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(WithdrawRequestDrawer);
