import React from "react";
import { Layout, Menu } from "antd";
import styles from "./Sider.module.scss";
import { HomeOutlined } from "@ant-design/icons";
import { SiderProps } from "antd/lib/layout";
import { useLocation, NavLink } from "react-router-dom";
import { IMenuItemType } from "router/routerType";
import { PRIVATE_ROUTES } from "router/Router.config";

const { Sider: AntDSider } = Layout;

interface IProps extends SiderProps {
  menuItems: IMenuItemType[];
}
const Sider = ({ menuItems, ...rest }: IProps) => {
  const location = useLocation();

  const getSubMenuOpenKey = (path) => {
    //get submenu key from location path
    return path.match(/[/]\w*/)[0];
  };

  return (
    <AntDSider {...rest} className={styles.sider}>
      <img
        alt="Logo"
        className={styles.logo}
        src={process.env.PUBLIC_URL + "/gfx/ii_Logo_Purple.png"}
      />
      <Menu
        theme="light"
        defaultSelectedKeys={getSubMenuOpenKey(location.pathname)}
        mode="inline"
        className={styles.menu}
      >
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <NavLink to={PRIVATE_ROUTES.APP_USERS_SCREEN.path}>App Users</NavLink>
        </Menu.Item>
        {menuItems?.map((item) => (
          <Menu.Item key={item.id} icon={<item.icon />}>
            <NavLink to={item.path}>{item.title}</NavLink>
          </Menu.Item>
        ))}
      </Menu>
    </AntDSider>
  );
};

export default Sider;
