import { memo, useEffect, useState, useCallback } from "react";
import Table from "ui/Table/Table";
import Search from "ui/Search/Search";
import { Card, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";

import PageHeader from "ui/PageHeader/PageHeader";
import Wrapper from "ui/Wrapper/Wrapper";
import { getInfluencers } from "features/influencers/api/influencersApi";
import { InfluencerType } from "features/influencers/types/InfuencerType";
import { ColumnsType } from "antd/lib/table";
import Button from "ui/Buttons/Button";
import ManageInfluencerDrawer from "./components/ManageInfluencerDrawer/ManageInfluencerDrawer";
import InfluencerDetailDrawer from "./components/InfluencerDetailDrawer/InfluencerDetailDrawer";

const InfluencersScreen = () => {
  const [influencers, setInfluencers] = useState<InfluencerType[]>();
  const [pagination, setPagination] = useState<object>();
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const location = useLocation();
  const history = useHistory();
  const [isManageDrawerVisible, setIsManageDrawerVisible] = useState(false);
  const [isDetailsDrawerVisible, setIsDetailsDrawerVisible] = useState(false);
  const [drawerInfluencerDetail, setDrawerInfluencerDetail] =
    useState<InfluencerType>();

  const [drawerInfluencerManage, setDrawerInfluencerManage] =
    useState<InfluencerType>();

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "ISIN",
      dataIndex: "isin",
      key: "isin",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Drop Status",
      children: [
        {
          title: "Status",
          dataIndex: "dropStatus",
          key: "dropStatus",
          render: (text) => <span>{text.dropStatus}</span>,
          width: "10%",
        },
        {
          title: "Latest Edition",
          dataIndex: "cards",
          key: "cards",
          width: "20%",
          render: (cards) => (
            <span>
              {!cards || cards.length === 0
                ? "-"
                : cards.map((c) => {
                    return (
                      c.currentDropLabel + " (" + c.currentDropStatus + ")"
                    );
                  })}
            </span>
          ),
        },
      ],
    },
    {
      title: "Image",
      dataIndex: "profileImageUrl",
      key: "profileImageUrl",
      align: "center",
      width: "100",
      render: (text) => (
        <span>
          <Image width={50} src={text} />
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 210,
      align: "center",
      render: (influencer: InfluencerType) => (
        <div>
          <Button
            style={{
              display: "inline-flex",
              width: 95,
            }}
            type="primary"
            shape="round"
            onClick={() => showDrawerManage(influencer)}
          >
            Manage
          </Button>
          <Button
            style={{
              display: "inline-flex",
              width: 95,
            }}
            type="primary"
            shape="round"
            onClick={() => showDrawerDetails(influencer)}
          >
            Details
          </Button>
        </div>
      ),
    },
  ];

  // const showActionButton = (influencer) => influencer.status === "new";

  const showDrawerManage = (influencer?: InfluencerType) => {
    setIsManageDrawerVisible(!isManageDrawerVisible);
    setTimeout(() => setDrawerInfluencerManage(influencer), 100);
  };

  const showDrawerDetails = (influencer?: InfluencerType) => {
    setIsDetailsDrawerVisible(!isDetailsDrawerVisible);
    setTimeout(() => setDrawerInfluencerDetail(influencer), 100);
  };
  const getInfluencersApi = useCallback(() => {
    setTableLoading(true);
    getInfluencers(qs.parse(location.search))
      .then((res) => {
        setInfluencers(res.rows);
        setPagination((prevPagination) => {
          return {
            ...prevPagination,
            total: res.count,
          };
        });
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, [location.search]);

  useEffect(() => {
    getInfluencersApi();
  }, [getInfluencersApi]);

  const handleTableChange = (pagination) => {
    const values = {
      ...qs.parse(location.search),
      page: pagination.current,
      per: pagination.pageSize,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  return (
    <>
      <PageHeader
        title={"Influencers"}
        extra={
          <Button
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => console.log("Plus button")}
          >
            Create a new
          </Button>
        }
      />
      <Wrapper>
        <Card extra={<Search />}>
          <Table
            loading={tableLoading}
            columns={columns}
            dataSource={influencers}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </Card>
      </Wrapper>

      <ManageInfluencerDrawer
        visible={isManageDrawerVisible}
        onClose={showDrawerManage}
        influencer={drawerInfluencerManage}
        showDrawer={showDrawerManage}
        refreshApi={getInfluencersApi}
      />

      <InfluencerDetailDrawer
        visible={isDetailsDrawerVisible}
        onClose={showDrawerDetails}
        influencer={drawerInfluencerDetail}
        showDrawer={showDrawerDetails}
        refreshApi={getInfluencersApi}
      />
    </>
  );
};

export default memo(InfluencersScreen);
