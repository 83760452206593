import React, { memo } from "react";
import styles from "./Search.module.scss";
import { Input } from "antd";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";

const { Search: AntDSearch } = Input;

interface IProps {
  onSearch?: (value) => void;
}

const Search = ({ onSearch }: IProps) => {
  // stores last known pagination values before user started searching for a phrase
  // const [latestPage, setLatestPage] = useState<{}>({});
  const location = useLocation();
  const history = useHistory();

  const handleOnSearch = (value) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({ searchterm: value }, { arrayFormat: "comma" }),
    });
  };

  // const handleOnSearchChange = (e) => {
  //   let params = { ...qs.parse(location.search) };
  //   let filteredParams: object = {};

  //   //include pagination params only if no searchterm
  //   params.page && setLatestPage({ page: params.page, per: params.per });

  //   if (!e.target.value) {
  //     filteredParams = latestPage;
  //   } else {
  //     filteredParams = {
  //       searchterm: e.target.value,
  //     };
  //   }

  //   history.push({
  //     pathname: location.pathname,
  //     search: qs.stringify(filteredParams, { arrayFormat: "comma" }),
  //   });
  // };

  return (
    <AntDSearch
      className={styles.search}
      placeholder="input search text"
      enterButton="Search"
      size="large"
      onSearch={(value) => handleOnSearch(value)}
    />
  );
};

export default memo(Search);
