import React, { memo, useCallback, useEffect, useState } from "react";
import { message, Image } from "antd";
import { InfluencerType } from "features/influencers/types/InfuencerType";
import styles from "./ManageInfluencerDrawer.module.scss";
import Drawer from "ui/Drawer/Drawer";
import Title from "ui/Typography/Title/Title";
import { DropProcesStatusType } from "features/influencers/types/DropProcesStatusType";
import {
  getInfluencerDropStatus,
  setStartDropProcessInfluencer,
} from "features/influencers/api/influencersApi";
import DropStatus from "./components/DropStatus/DropStatus";
import Button from "ui/Buttons/Button";
import Loading from "ui/Loading/Loading";
interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  influencer?: InfluencerType;
  showDrawer?: () => void;
}

const ManageInfluencerDrawer = ({
  visible,
  refreshApi,
  onClose,
  influencer,
}: IProps) => {
  const onCloseDrawer = () => {
    onClose && onClose();
  };
  const [influencerDropStatusLoading, setInfluencerDropStatusLoading] =
    useState<boolean>(false);
  const [influencerDropStatusReady, setInfluencerDropStatusReady] =
    useState<boolean>(false);
  const [influencerStatus, setInfluencerStatus] =
    useState<DropProcesStatusType>();

  const getInfluencerStatusApi = useCallback(() => {
    if (visible && influencer) {
      setInfluencerDropStatusLoading(true);
      getInfluencerDropStatus(influencer.id)
        .then((res) => {
          setInfluencerStatus(res);
          if (res?.dropStatus.toLowerCase() === "ready") {
            setInfluencerDropStatusReady(true);
          }
        })
        .catch((e) => message.error("Couldn't load Status"))
        .finally(() => setInfluencerDropStatusLoading(false));
    }
  }, [influencer, visible]);

  const setStartDropProcessApi = (influencer) => {
    if (influencer) {
      setInfluencerDropStatusLoading(true);
      setStartDropProcessInfluencer(influencer.id)
        .then((res) => {
          setInfluencerDropStatusReady(false);
        })
        .catch((e) => message.error("Couldn't start the drop process"))
        .finally(() => {
          setInfluencerDropStatusLoading(false);
          refreshDrawerApi();
        });
    }
  };
  const refreshDrawerApi = () => {
    getInfluencerStatusApi();
    refreshApi && refreshApi();
  };

  useEffect(() => {
    getInfluencerStatusApi();
  }, [getInfluencerStatusApi]);

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={700}
    >
      <div className={styles.itemDataWrapper}>
        {influencer && (
          <Image
            width={50}
            className={styles.influencerImage}
            src={influencer?.profileImageUrl}
          />
        )}
        <Title level={3} className={styles.influencerName}>
          {influencer?.name}
        </Title>
      </div>

      <div className={styles.dropStatusHeader}>
        <DropStatus
          data={influencerStatus}
          visible={influencerDropStatusLoading}
        ></DropStatus>
      </div>
      <div className={styles.dropStatusHeader}>
        {!influencerDropStatusLoading && (
          <>
            <Button
              style={{
                width: "180px",
              }}
              type="dashed"
              size="large"
              shape="round"
              loading={influencerDropStatusLoading}
              disabled={!influencerDropStatusReady}
              onClick={() => {
                setStartDropProcessApi(influencer);
              }}
            >
              Start drops process
            </Button>
          </>
        )}
        {influencerDropStatusLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(ManageInfluencerDrawer);
