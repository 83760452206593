import AuthScreen from "features/identity/AuthScreen";
import { IMenuItemType } from "router/routerType";

export const SIGN_UP_SCREEN: IMenuItemType = {
  id: "signup",
  path: "/sign-up",
  component: AuthScreen,
};

/** Screen: Login Page */
export const LOGIN_SCREEN: IMenuItemType = {
  id: "login",
  path: "/",
  component: AuthScreen,
};

/** Screen: Forgot Password Page */
export const FORGOT_PASSWORD_SCREEN: IMenuItemType = {
  id: "forgot-password",
  path: "/forgot-password",
  component: AuthScreen,
};

/** Screen: Set New Password Page */
export const SET_NEW_PASSWORD_SCREEN: IMenuItemType = {
  id: "set-new-password",
  path: `/set-new-password`,
  component: AuthScreen,
};

export const SET_NEW_PASSWORD_CODE_SCREEN: IMenuItemType = {
  id: "set-new-password-code",
  path: `/set-new-password-code`,
  component: AuthScreen,
};
