import { DropProcesStatusType } from "features/influencers/types/DropProcesStatusType";
import styles from "./DropStatus.module.scss";
import ListItem from "ui/Lists/Items/ListItem/ListItem";
import Button from "ui/Buttons/Button";
import { List } from "antd";
import { useState } from "react";

interface IProps {
  data?: DropProcesStatusType;
  visible: boolean;
}

const DropStatus = ({ data, visible }: IProps) => {
  let nrOfCards = false;
  let currentSeries = false;
  const [marketPairsLoading, setMarketPairsLoading] = useState<boolean>(false);

  const getTextFromStatus = (status: string | undefined) => {
    if (status) {
      if (status.toLowerCase() === "notready") {
        return "No card images created";
      }
      if (status.toLowerCase() === "error")
        return "Card images configuration error";
      if (status.toLowerCase() === "ready") {
        nrOfCards = true;
        return "Ready for dropping";
      }
      if (status.toLowerCase() === "dropping") {
        nrOfCards = true;
        currentSeries = true;
        return "Already dropping";
      }
      if (status.toLowerCase() === "stopped") {
        nrOfCards = true;
        currentSeries = true;
        return "Drops processed stopped (no more card images)";
      }
    }
  };

  return (
    <div className={styles.itemDataWrapper}>
      <List className={styles.drawerActionsList} size="large">
        <ListItem type="primary">
          {getTextFromStatus(data?.dropStatus)}
        </ListItem>

        {nrOfCards ? (
          <ListItem type="primary"> Nº of cards {data?.numCards}</ListItem>
        ) : (
          " "
        )}
        {currentSeries ? (
          <ListItem type="primary">
            {" "}
            Current Series {data?.currentSeriesDropping}
          </ListItem>
        ) : (
          " "
        )}
      </List>
    </div>
  );
};

export default DropStatus;
