import React, { PropsWithChildren } from "react";
import {
  Input as AntDInput,
  Select as AntDSelect,
  InputNumber as AntDInputNumber,
  DatePicker as AntDDatePicker,
} from "antd";
import { InputProps, TextAreaProps } from "antd/lib/input";
import styles from "./Inputs.module.scss";
import { SelectProps } from "antd/lib/select";
import { InputNumberProps } from "antd/lib/input-number";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

export const Input = ({ children, ...rest }: PropsWithChildren<InputProps>) => {
  return (
    <AntDInput size={"large"} {...rest} className={styles.inputBasic}>
      {children}
    </AntDInput>
  );
};

export const InputNumber = ({
  children,
  className,
  ...rest
}: PropsWithChildren<InputNumberProps>) => {
  const classNames = cx(styles.inputNumber, className);

  return (
    <AntDInputNumber size={"large"} {...rest} className={classNames}>
      {children}
    </AntDInputNumber>
  );
};

export const InputPassword = ({
  children,
  ...rest
}: PropsWithChildren<InputProps>) => (
  <AntDInput.Password size={"large"} {...rest} className={styles.inputPassword}>
    {children}
  </AntDInput.Password>
);

export const TextArea = ({
  children,
  ...rest
}: PropsWithChildren<TextAreaProps>) => (
  <AntDInput.TextArea autoSize {...rest} className={styles.inputTextarea}>
    {children}
  </AntDInput.TextArea>
);

export const Select = ({
  children,
  ...rest
}: PropsWithChildren<SelectProps<"tags">>) => (
  <AntDSelect size={"large"} {...rest} className={styles.inputSelect}>
    {children}
  </AntDSelect>
);

export const SelectOption = ({ children, value, key, ...rest }) => (
  <AntDSelect.Option {...rest} value={value} key={key}>
    {children}
  </AntDSelect.Option>
);

export const DatePicker = ({ children, ...rest }) => (
  <AntDDatePicker {...rest}>{children}</AntDDatePicker>
);
