// Auth helper for storing and getting token data from local storage
import jwt_decode, { JwtPayload } from "jwt-decode";

const ACCESS_TOKEN = "ii-admin-panel-access-token";
const REFRESH_TOKEN = "ii-admin-panel-refresh-token";
const ID_TOKEN = "ii-admin-panel-id-token";

export const isAuthenticated = () => {
  return getAccessToken() != null;
};

export const setAccessToken = (token: string) => {
  return localStorage.setItem(ACCESS_TOKEN, token);
};

export const setIdToken = (token: string) => {
  return localStorage.setItem(ID_TOKEN, token);
};

export const getIdToken = () => {
  return localStorage.getItem(ID_TOKEN);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setRefreshToken = (token: string) => {
  return localStorage.setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ID_TOKEN);
};

export const getTokens = () => {
  const accessToken = getAccessToken();
  const idToken = getIdToken();

  return (
    accessToken && {
      //TODO: FIX TOKEN SWAP
      accessToken: idToken,
      expireAt: idToken && jwt_decode<JwtPayload>(accessToken)?.exp,
      refreshToken: getRefreshToken(),
      idToken: getIdToken(),
    }
  );
};
