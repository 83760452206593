import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import Drawer from "ui/Drawer/Drawer";
import { AppUserType } from "features/appUsers/types/AppUserType";
import Loading from "ui/Loading/Loading";
import { getTransactionsPerAppUser } from "features/appUsers/api/appUsersApi";
import { errorResponseMessages } from "api/apiErrorHelper";
import { useLocation } from "react-router-dom";
import { AppUserTransaction } from "features/appUsers/types/AppUserTransaction";
import styles from "./TransactionsDrawer.module.scss";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const TransactionsDrawer = ({
  visible,
  refreshApi,
  onClose,
  appUser,
}: IProps) => {
  const [appUserTransactionLoading, setappUserTransactionLoading] =
    useState<boolean>(false);
  const [appUserTransaction, setappUserTransaction] =
    useState<AppUserTransaction[]>();
  const location = useLocation();

  const onCloseDrawer = () => {
    onClose && onClose();
  };

  const geAppUserOrderAPI = useCallback(() => {
    if (appUser) {
      setappUserTransactionLoading(true);
      getTransactionsPerAppUser(appUser?.id)
        .then((res) => {
          setappUserTransaction(res);
        })
        .catch((e) => {
          errorResponseMessages(e);
        })
        .finally(() => {
          setappUserTransactionLoading(false);
        });
    }
  }, [appUser, visible]);

  useEffect(() => {
    geAppUserOrderAPI();
  }, [geAppUserOrderAPI]);

  const columns: ColumnsType = [
    {
      title: "orderId",
      dataIndex: "orderId",
      key: "orderId",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "totalValue",
      dataIndex: "totalValue",
      key: "totalValue",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "quantity",
      dataIndex: "qty",
      key: "qty",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Card",
      children: [
        {
          title: "isin",
          dataIndex: "isin",
          key: "isin",
          render: (text) => <span>{text}</span>,
          width: "8%",
        },
        {
          title: "name",
          dataIndex: "name",
          key: "name",
          render: (text) => <span>{text}</span>,
          width: "10%",
        },
      ],
    },

    {
      title: "latestPrice",
      dataIndex: "latestPrice",
      key: "latestPrice",
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={"90%"}
    >
      <div className={styles.dropStatusHeadder}>
        {!appUserTransactionLoading && (
          <>
            <Card>
              <Table
                loading={appUserTransactionLoading}
                columns={columns}
                dataSource={appUserTransaction}
              />
            </Card>
          </>
        )}
        {appUserTransactionLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(TransactionsDrawer);
