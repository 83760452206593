import React, { memo } from "react";
import ErrorScreen, { ClientErrors } from "router/ErrorScreen";

interface IProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  requiredPermissions: string[];
  userPermissions?: {};
  redirect?: string;
}

const Permission = ({
  children,
  requiredPermissions,
  userPermissions,
  redirect,
  fallback,
}: IProps) => {
  let allowed = false;

  requiredPermissions.forEach((requiredPermission: string) => {
    allowed = userPermissions && userPermissions[requiredPermission];
  });

  if (allowed) {
    return <>{children}</>;
  } else if (requiredPermissions.length === 0) {
    return <>{children}</>;
  } else if (!allowed) {
    return <ErrorScreen status={ClientErrors.FORBIDDEN} />;
  } else {
    return <></>;
  }
};

export default memo(Permission);
