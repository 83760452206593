/** Private route imports */
import * as DASHBOARD_ROUTES from "features/dashboard/routers/router.dashboard";
import * as PROFILE_ROUTES from "features/identity/Profile/routers/router.profile";
import * as INFLUENCERS_ROUTES from "features/influencers/routers/router.influencers";
import * as APP_USERS_ROUTES from "features/appUsers/routers/router.appUsers";
import * as SETS_ROUTES from "features/sets/routers/router.sets";
/** Public route imports */
import * as AUTH_ROUTES from "features/identity/router.authentication";

/** Private Routes */
export const PRIVATE_ROUTES = {
  ...DASHBOARD_ROUTES,
  ...PROFILE_ROUTES,
  ...INFLUENCERS_ROUTES,
  ...APP_USERS_ROUTES,
  ...SETS_ROUTES,
};

/** Public Routes */
export const PUBLIC_ROUTES = { ...AUTH_ROUTES };

/** SiderMenu Items */
export const SIDER_MENU = [
  INFLUENCERS_ROUTES.INFLUENCERS_SCREEN,
  SETS_ROUTES.SETS_SCREEN,
];
