import React, { memo, useState } from "react";
import { Layout as AntDLayout } from "antd";
import styles from "./Layout.module.scss";
import Header from "./Header/Header";
import Content from "./Content/Content";
import Sider from "./Sider/Sider";
import { IMenuItemType } from "router/routerType";
import cx from "classnames";

interface IProps {
  children: React.ReactNode;
  siderMenu: [];
  item?: IMenuItemType;
}

const { Footer } = AntDLayout;

const Layout = ({ children, siderMenu }: IProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <AntDLayout className={styles.layout}>
      <Sider
        collapsed={collapsed}
        onCollapse={onCollapse}
        menuItems={siderMenu}
      />
      <AntDLayout>
        <Header collapsed={!collapsed} />
        <Content collapsed={collapsed}>{children}</Content>
        <Footer
          className={cx(styles.footer, {
            [styles["footer--collapsed"]]: !collapsed,
          })}
        >
          Influencer Index Admin Panel
        </Footer>
      </AntDLayout>
    </AntDLayout>
  );
};

export default memo(Layout);
