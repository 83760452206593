import { memo, useEffect, useState, useCallback } from "react";
import Table from "ui/Table/Table";
import Search from "ui/Search/Search";
import { Card, Menu } from "antd";
import Button from "ui/Buttons/Button";
import { PlusOutlined } from "@ant-design/icons";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import Wrapper from "ui/Wrapper/Wrapper";
import { getAppUsers } from "features/appUsers/api/appUsersApi";
import { AppUserType } from "features/appUsers/types/AppUserType";
import { ColumnsType } from "antd/lib/table";
import PaymentDrawer from "./components/PaymentsDrawer/PaymentsDrawer";
import CardsHoldingDrawer from "./components/CardsHoldingDrawer/CardsHoldingDrawer";
import OrdersHistoryDrawer from "./components/OrdersHistoryDrawer/OrdersHistoryDrawer";
import TransactionDrawer from "./components/TransactionsListDrawer/TransactionsDrawer";
import WithdrawRequestDrawer from "./components/WithdrawRequestDrawer/WithdrawRequestDrawer";

const AppUsersScreen = () => {
  const [appUsers, setAppUsers] = useState<AppUserType[]>();
  const [pagination, setPagination] = useState<object>();
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [isCardsDrawerVisible, setCardsDrawerVisible] = useState(false);
  const [isOrdersDrawerVisible, setOrdersDrawerVisible] = useState(false);
  const [isTransactionsDrawerVisible, setTransactionsDrawerVisible] =
    useState(false);
  const [isPaymentsDrawerVisible, setPaymentsDrawerVisible] = useState(false);
  const [isWithdrawRequestDrawer, setWithdrawRequestDrawerVisible] =
    useState(false);

  const [TransactionsDrawer, setTransactionsDrawer] = useState<AppUserType>();
  const [OrdersDrawer, setOrdersDrawer] = useState<AppUserType>();
  const [CardsDrawer, setCardsDrawer] = useState<AppUserType>();
  const [PaymentsDrawer, setPaymentsDrawer] = useState<AppUserType>();
  const [withdrawRequestDrawer, setWithdrawRequestDrawer] =
    useState<AppUserType>();

  const location = useLocation();
  const history = useHistory();
  const isManageDrawerVisible = true;

  const columns: ColumnsType = [
    {
      title: "Phone #",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <span>{text}</span>,
      width: "14%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span>{text}</span>,
      width: "14%",
    },
    {
      title: "Status",
      dataIndex: "ledgerStatus",
      key: "DAMLPartyId",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: "KYC Status",
      children: [
        {
          title: "Eidvcheck",
          dataIndex: "eidvcheckStatus",
          key: "eidvcheckStatus",
          render: (text) => <span>{text}</span>,
          width: "11%",
        },
        {
          title: "Docvcheck",
          dataIndex: "docvcheckStatus",
          key: "docvcheckStatus",
          render: (text) => <span>{text}</span>,
          width: "11%",
        },
      ],
    },
    {
      title: "Wallet $",
      children: [
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (text) => <span>{text}</span>,
          width: "10%",
        },
        {
          title: "Locked",
          dataIndex: "amountLocked",
          key: "amountLocked",
          render: (text) => <span>{text}</span>,
          width: "10%",
        },
      ],
    },
    {
      title: "Actions",
      key: "actions",
      width: "5%",
      align: "center",
      render: (appUser: AppUserType) => (
        <Menu mode="horizontal">
          <Menu.Item>
            <Button
              style={{
                display: "inline-flex",
                width: 170,
              }}
              type="primary"
              shape="round"
              onClick={() => showDrawerPayments(appUser)}
            >
              Payments
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              style={{
                display: "inline-flex",
                width: 170,
              }}
              type="primary"
              shape="round"
              onClick={() => showWithdrawRequestDrawer(appUser)}
            >
              Withdraw Requests
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              style={{
                display: "inline-flex",
                width: 170,
              }}
              type="primary"
              shape="round"
              onClick={() => showDrawerCards(appUser)}
            >
              Cards
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              style={{
                display: "inline-flex",
                width: 170,
              }}
              type="primary"
              shape="round"
              onClick={() => showDrawerOrders(appUser)}
            >
              Orders
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              style={{
                display: "inline-flex",
                width: 170,
              }}
              type="primary"
              shape="round"
              onClick={() => showDrawerTransactions(appUser)}
            >
              Transactions
            </Button>
          </Menu.Item>
        </Menu>
      ),
    },
  ];

  const showDrawerCards = (appUser?: AppUserType) => {
    setCardsDrawerVisible(!isCardsDrawerVisible);
    setTimeout(() => setCardsDrawer(appUser), 100);
  };
  const showDrawerOrders = (appUser?: AppUserType) => {
    setOrdersDrawerVisible(!isOrdersDrawerVisible);
    setTimeout(() => setOrdersDrawer(appUser), 100);
  };

  const showDrawerTransactions = (appUser?: AppUserType) => {
    setTransactionsDrawerVisible(!isTransactionsDrawerVisible);
    setTimeout(() => setTransactionsDrawer(appUser), 100);
  };

  const showDrawerPayments = (appUser?: AppUserType) => {
    setPaymentsDrawerVisible(!isPaymentsDrawerVisible);
    setTimeout(() => setPaymentsDrawer(appUser), 100);
  };

  const showWithdrawRequestDrawer = (appUser?: AppUserType) => {
    setWithdrawRequestDrawerVisible(!isWithdrawRequestDrawer);
    setTimeout(() => setWithdrawRequestDrawer(appUser), 100);
  };

  const getAppUsersApi = useCallback(() => {
    setTableLoading(true);
    getAppUsers(qs.parse(location.search))
      .then((res) => {
        setAppUsers(res.rows);
        setPagination((prevPagination) => {
          return {
            ...prevPagination,
            total: res.count,
          };
        });
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, [location.search]);

  useEffect(() => {
    getAppUsersApi();
  }, [getAppUsersApi]);

  const handleTableChange = (pagination) => {
    const values = {
      ...qs.parse(location.search),
      page: pagination.current,
      per: pagination.pageSize,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  return (
    <>
      <PageHeader
        title={"App Users"}
        extra={
          <Button
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => console.log("Plus button")}
          >
            Create a new
          </Button>
        }
      />
      <Wrapper>
        <Card extra={<Search />}>
          <Table
            loading={tableLoading}
            columns={columns}
            dataSource={appUsers}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </Card>
      </Wrapper>

      <PaymentDrawer
        visible={isPaymentsDrawerVisible}
        onClose={showDrawerPayments}
        appUser={PaymentsDrawer}
        showDrawer={showDrawerPayments}
        refreshApi={getAppUsersApi}
      />
      <CardsHoldingDrawer
        visible={isCardsDrawerVisible}
        onClose={showDrawerCards}
        appUser={CardsDrawer}
        showDrawer={showDrawerCards}
        refreshApi={getAppUsersApi}
      />
      <TransactionDrawer
        visible={isTransactionsDrawerVisible}
        onClose={showDrawerTransactions}
        appUser={TransactionsDrawer}
        showDrawer={showDrawerTransactions}
        refreshApi={getAppUsersApi}
      />
      <OrdersHistoryDrawer
        visible={isOrdersDrawerVisible}
        onClose={showDrawerOrders}
        appUser={OrdersDrawer}
        showDrawer={showDrawerOrders}
        refreshApi={getAppUsersApi}
      />

      <WithdrawRequestDrawer
        visible={isWithdrawRequestDrawer}
        onClose={showWithdrawRequestDrawer}
        appUser={withdrawRequestDrawer}
        showDrawer={showWithdrawRequestDrawer}
        refreshApi={getAppUsersApi}
      />
    </>
  );
};

export default memo(AppUsersScreen);
