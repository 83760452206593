import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import Drawer from "ui/Drawer/Drawer";
import Button from "ui/Buttons/Button";
import Loading from "ui/Loading/Loading";
import Title from "ui/Typography/Title/Title";
import ListItem from "ui/Lists/Items/ListItem/ListItem";
import { message, List } from "antd";
import { InfluencerType } from "features/influencers/types/InfuencerType";
import styles from "./InfluencerDetailDrawer.module.scss";
import { getInfluencerDetails } from "features/influencers/api/influencersApi";
import {
  InfluencerDetailsType,
  CardDetail,
  InfluencerStats,
} from "features/influencers/types/InfluencerDetails";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  influencer?: InfluencerType;
  showDrawer?: () => void;
}

const InfluencerDetailDrawer = ({
  visible,
  refreshApi,
  onClose,
  influencer,
}: IProps) => {
  const onCloseDrawer = () => {
    onClose && onClose();
  };
  const [influencerDetailLoading, setInfluencerDetailLoading] =
    useState<boolean>(false);
  const [influencerDetailReady, setInfluencerDetailReady] =
    useState<boolean>(false);

  const [influencerDetail, setInfluencerDetail] =
    useState<InfluencerDetailsType>();
  const [influencerCardsDetail, setinfluencerCardsDetail] =
    useState<CardDetail[]>();
  const [influencerStatsDetail, setinfluencerStatsDetail] =
    useState<InfluencerStats>();

  const getInfluencerStatusApi = useCallback(() => {
    if (visible && influencer) {
      setInfluencerDetailLoading(true);
      getInfluencerDetails(influencer.id)
        .then((res) => {
          setInfluencerDetail(res);
          console.log(res);
          setInfluencerDetailReady(true);
        })
        .catch((e) =>
          message.error("Couldn't fetch the data from influencer details")
        )
        .finally(() => {
          setInfluencerDetailLoading(false);
        });
    }
  }, [influencer, visible]);

  useEffect(() => {
    getInfluencerStatusApi();
  }, [getInfluencerStatusApi]);

  const columns: ColumnsType = [
    {
      title: "AssetLabel",
      dataIndex: "assetLabel",
      key: "assetLabel",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "LatestPrice",
      dataIndex: "latestPrice",
      key: "latestPrice",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "StartDate",
      dataIndex: "dropStartDate",
      key: "dropStartDate",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "EndDate",
      dataIndex: "dropEndDate",
      key: "dropEndDate",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      align: "center",
      width: "100",
      render: (text) => (
        <span>
          <Image width={50} src={text} />
        </span>
      ),
    },
  ];

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width="80%"
    >
      <div className={styles.dropStatusHeadder}>
        {!influencerDetailLoading && (
          <>
            <Card>
              <Title level={2}>{influencer?.name} </Title>
              <Title level={2}>{influencer?.ISIN} </Title>
              <div className={styles.itemDataWrapper}>
                <List
                  className={styles.drawerActionsList}
                  size="default"
                  itemLayout="vertical"
                >
                  <ListItem type="primary">
                    <Title level={5}>Record Date: </Title>
                    {influencerDetail?.influencerStats.recordDate}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Volume: </Title>
                    {influencerDetail?.influencerStats.volume}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Volume Change: </Title>
                    {influencerDetail?.influencerStats.volumeChange}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Capitalization: </Title>
                    {influencerDetail?.influencerStats.capitalization}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Drop Time Avg: </Title>
                    {influencerDetail?.influencerStats.dropTimeAvg}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Buys: </Title>
                    {influencerDetail?.influencerStats.buys}
                  </ListItem>
                </List>
                <br></br>
                <List
                  className={styles.itemDataWrapper}
                  itemLayout="vertical"
                  size="default"
                >
                  <ListItem type="primary">
                    <Title level={5}>Sells: </Title>
                    {influencerDetail?.influencerStats.sells}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Holders Total: </Title>
                    {influencerDetail?.influencerStats.holdersTotal}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Cards Total: </Title>
                    {influencerDetail?.influencerStats.cardsTotal}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Top Sale Price: </Title>
                    {influencerDetail?.influencerStats.topSalePrice}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Drop Bidders Avg: </Title>
                    {influencerDetail?.influencerStats.dropBiddersAvg}
                  </ListItem>
                  <ListItem type="primary">
                    <Title level={5}>Bid Win Ratio: </Title>
                    {influencerDetail?.influencerStats.bidWinRatio}
                  </ListItem>
                </List>
              </div>
            </Card>

            <Card>
              <Table
                loading={influencerDetailLoading}
                columns={columns}
                dataSource={influencerDetail?.cards}
              />
            </Card>
          </>
        )}
        {influencerDetailLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(InfluencerDetailDrawer);
