import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import Drawer from "ui/Drawer/Drawer";
import { AppUserType } from "features/appUsers/types/AppUserType";
import Loading from "ui/Loading/Loading";
import styles from "./CardsHoldingDrawer.module.scss";
import { getCardsPerAppUser } from "features/appUsers/api/appUsersApi";
import { AppUserCards } from "features/appUsers/types/AppUserCards";
import { errorResponseMessages } from "api/apiErrorHelper";
import { useLocation, useHistory } from "react-router-dom";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const CardsHoldingDrawer = ({
  visible,
  refreshApi,
  onClose,
  appUser,
}: IProps) => {
  const [appUserCardsLoading, setappUserCardsLoading] =
    useState<boolean>(false);
  const [appUserCards, setappUserCards] = useState<AppUserCards[]>();
  const location = useLocation();

  const onCloseDrawer = () => {
    onClose && onClose();
  };

  const geAppUserCardApi = useCallback(() => {
    if (appUser) {
      setappUserCardsLoading(true);
      getCardsPerAppUser(appUser?.id)
        .then((res) => {
          setappUserCards(res);
        })
        .catch((e) => {
          errorResponseMessages(e);
        })
        .finally(() => {
          setappUserCardsLoading(false);
        });
    }
  }, [appUser, visible]);

  useEffect(() => {
    geAppUserCardApi();
  }, [geAppUserCardApi]);

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "ISIN",
      dataIndex: "isin",
      key: "isin",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "AssetLabel",
      dataIndex: "assetLabel",
      key: "assetLabel",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "CardsNumber",
      dataIndex: "cardsNumber",
      key: "cardsNumber",
      width: "20%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      align: "center",
      width: "100",
      render: (text) => (
        <span>
          <Image width={50} src={text} />
        </span>
      ),
    },
  ];

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={830}
    >
      <div className={styles.dropStatusHeadder}>
        {!appUserCardsLoading && (
          <>
            <Card>
              <Table
                loading={appUserCardsLoading}
                columns={columns}
                dataSource={appUserCards}
              />
            </Card>
          </>
        )}
        {appUserCardsLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(CardsHoldingDrawer);
