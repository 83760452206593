import { IMenuItemType } from "router/routerType";
import { UserSwitchOutlined } from "@ant-design/icons";
import InfluencersScreen from "../screens/InfluencersScreen/InfluencersScreen";

/** Screen: Dashboard Page */
export const INFLUENCERS_SCREEN: IMenuItemType = {
  id: "influencers",
  path: "/influencers",
  component: InfluencersScreen,
  title: "Influencers",
  icon: UserSwitchOutlined,
  permissions: [],
};
